import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import Slider from "react-slick";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { GoDash } from "react-icons/go";


export const Roadmap = () => {

  const settings = {
    className: "center",
    arrows: false,
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          dots: true
        }
      }
    ]
  };

  return (
    <section className="contact" id="roadmap">
      <Container fluid>
        <Row className="roadMap-active">
          <TrackVisibility>
            {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
              <div className="text-center">
                <h2>Roadmap</h2>
              </div>
              <div>
                <Slider {...settings}>
                  <Col lg={4}>
                    <div className="roadmap-item">
                      <span className="roadmap-title">Phase 1</span>
                      <div className="roadmap-content">
                        <h4 className="title">
                          <span className="dot"></span> Nano Recon
                        </h4>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <IoCheckmarkDoneSharp className="roadmap-item-done"/>
                          <div className="text-white text-base font-normal leading-snug">Launch Emo Pet AI Website</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">{process.env.REACT_APP_TOKEN_SYMBOL} Fair Launch</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Build & Grow Community</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug mb-2">Stake to Earn Rewards</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Coingecko & Coinmarketcap listing</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="roadmap-item">
                      <span className="roadmap-title">Phase 2</span>
                      <div className="roadmap-content">
                        <h4 className="title">
                          <span className="dot"></span>Cyber Nexus
                        </h4>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">DApp Launch & Exclusive NFT Minting (Limited Edition 500)</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Ordering of 500 Default EMO Units</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Innovative Parts Implementation via Minted NFTs</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Fusion of Cutting-Edge Software for Enhanced EMO Units</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Exquisite EMO Unit Delivery to Proud Owners</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="roadmap-item">
                      <span className="roadmap-title">Phase 3</span>
                      <div className="roadmap-content">
                        <h4 className="title">
                          <span className="dot"></span>Tech Triumph
                        </h4>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Pioneering Custom EMO Parts Marketplace Construction</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Innovative Revenue Sharing for Stakeholders and OG Minters</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Revolutionary DApp Evolution - Version 2 Unveiling</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Immersive EMO Experience: Interactive Dashboard via DApp</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="roadmap-item">
                      <span className="roadmap-title">Phase 4</span>
                      <div className="roadmap-content">
                        <h4 className="title">
                          <span className="dot"></span>Robotic Precision
                        </h4>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Cutting-Edge Research and Development for AI Innovations</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Futuristic Integration: EMO Identity Powered by Web 3 Wallets</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Empowering EMO: AI-Driven Trading Decisions and Technical Analysis</div>
                        </div>
                        <div className="d-flex items-center gap-2 align-items-center mb-2">
                          <GoDash />
                          <div className="text-white text-base font-normal leading-snug">Revolutionizing Communication: EMO to EMO Blockchain Text Messages</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Slider>
              </div>
            </div>}
          </TrackVisibility>
        </Row>
      </Container>
    </section>
  )
}
