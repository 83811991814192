import { Col } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import { BsAndroid2 } from "react-icons/bs"
import { FaAppStoreIos } from "react-icons/fa"


export const Newsletter = ({ status, message, onValidated }) => {

  return (
      <Col lg={12}>
        <div className="download-bx wow slideInUp text-center">
          <h3>Download EMO Pet App</h3>
          <div className="mb-4">
            <Badge bg="primary">Available Soon</Badge>
          </div>
          <div className="social-icon">
            <a href="#"><FaAppStoreIos  size={25}/></a>
            <a href="#"><BsAndroid2  size={25}/></a>
          </div>
        </div>
      </Col>
  )
}
