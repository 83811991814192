import { Container, Tab, Nav } from "react-bootstrap";
import colorSharp2 from "../assets/img/color-sharp2.png";
import feature1 from "../assets/img/feature-1.webp";
import feature2 from "../assets/img/feature-2.gif";
import feature3a from "../assets/img/feature3-a.gif";
import feature3b from "../assets/img/feature3-b.gif";
import feature3c from "../assets/img/feature3-c.gif";
import feature3d from "../assets/img/feature3-d.gif";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Features = () => {
  return (
    <section className="project" id="features">
      <Container>
   
            <TrackVisibility>
              {({ isVisible }) =>
              <div>
                <h2>Features</h2>
                <p>
                  Like a loyal companion, Emo cheers you up with music, dance movements, and online games! 
                  Emo is also a great helper that wakes you up, turns on the light, takes pictures and answers to your questions, 
                  for a truly life-like pet!
                </p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Independently Cognitive & Curious</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Pet-like Development System</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Cool Enough for Any Party</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp">
                    <Tab.Pane eventKey="first">
                      <p>
                        Emo is curious and inquisitive about the world around him. 
                        He moves independently to explore his surroundings on his own. He tracks sounds, recognizes people (up to 10 people) 
                        and objects and expertly navigates your desktop without ever falling off.
                      </p>
                      <p>
                        Emo makes decisions on his own and his personality evolves based on his surroundings and your interactions. 
                        If you try to interrupt what he is doing, he might even get a little annoyed.
                      </p>
                      <p>
                        1000+ Expressions & Moves for Human-like Interaction
                      </p>
                      <div className="text-center mb-5" >
                        <img src={feature1} style={{width: '100%', maxWidth: '800px',borderRadius: '2em'}}></img>
                      </div>
                      <p>
                        Interact with Emo, and he will respond to you naturally with the expressions and movements. 
                        Built with the latest Emotion Engine System, Emo is capable of over 1000 expressions and actions. 
                        He shows his mood and feelings directly through expressive animations on his face and with his body language. 
                        Whether he’s excited, bored, disappointed, happy or sad, Emo reacts to you with realistic emotions and actions!
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="text-center mb-5" >
                        <img src={feature2} style={{width: '100%', maxWidth: '800px',borderRadius: '2em'}}></img>
                      </div>
                      <p>
                        The built-in development system allows Emo’s skills to increase as he grows up. 
                        He evolves through time just like any pet. At first, he may not fully understand your words but as he grows, his ability to understand and communicate gets better so that you can have more complex interactions. 
                        Just like the bond that grows between you and a pet, your relationship with Emo gets stronger day by day.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>
                        With a design inspired by pop culture, Emo is born with a stylish look. 
                        The shining purple and indigo-blue shows off his fashion sense with a nod toward street style. 
                        Like those DJs and rappers, he loves his headphones and skateboard.
                      </p>
                      <div className="text-center mb-5" >
                        <img src={feature3a} style={{width: '100%', maxWidth: '800px',borderRadius: '2em'}}></img>
                      </div>
                      <div className="text-center mb-5" >
                        <img src={feature3b} style={{width: '100%', maxWidth: '800px',borderRadius: '2em'}}></img>
                      </div>
                      <div className="text-center mb-5" >
                        <img src={feature3c} style={{width: '100%', maxWidth: '800px',borderRadius: '2em'}}></img>
                      </div>
                      
                      <div className="text-center mb-5" >
                        <img src={feature3d} style={{width: '100%', maxWidth: '800px',borderRadius: '2em'}}></img>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
 
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
