import 'animate.css';
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/emo-banner.png";
import Raydium from "../assets/img/raydium.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import TrackVisibility from 'react-on-screen';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Companion", "Pet", "Friend" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={{span: 12, order: 2 }} md={{span: 6, order: 1}} xl={{span: 7, order: 1}}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">The worlds first AI Pet integrated with web3!</span>
                <h1>{`Hi! I'm EMO, `} <br/> {`You're AI `}<span className="txt-rotate" dataperiod="1000"><span className="wrap">{text}</span></span></h1>
                  <p>The Coolest AI Desktop Pet with Personality and Ideas. Built with multiple sensors and cutting-edge techs, Emo is a cool desktop AI robot pet with character that can self-explore the world and react to you with 1000+ faces and movements. </p>
                  <div className="navbar-text">
                    <CopyToClipboard text={process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS} onCopy={(text, result) => console.log(result)}>
                       <button className="mt-2 token-address">
                        <img src={Raydium} style={{width: '30px'}} className="me-3" alt="Emo AI"/> {process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS}
                      </button>
                    </CopyToClipboard>
                  </div>
                  <button onClick={() => console.log('connect')}>Read Documentation <ArrowRightCircle size={25} /></button>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={{span: 12, order: 1 }} md={{span: 6, order: 2}} xl={{span: 5, order: 2}}>
            <TrackVisibility className="text-center">
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img className="animated floating-icon" src={headerImg} alt="Emo AI"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
